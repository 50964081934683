import React, { useEffect } from "react";
import {useProfileContext} from "../lib/contextLib";
import { useGlobalLoaderContext } from "../lib/contextLib";
import { useNavigate } from "react-router-dom";
import { LinkedEventCard, EventCardApplied } from "../components/Cards";
import { VStack, Box } from "@chakra-ui/react";

/*
Events&Bierstriche: need to be loaded on every refresh. 
Otherwise no update until logout

I assume that specific event data could go into local storage

*/



/* ToDo: 
Strategy: events for which I have not been accepted should also appear in the EventList marked as "not accepted yet."
-> Button for refresh, status may be updated 
    -> reload from API
    -> if accepted: update in localStorage (or update anytime, does not matter)
ToDo: 
- load events from API as dictionary. Store in local storage. 
- Modify API endpoint to retrieve events: Return data based on eventregistrations: 
-- include property "accepted" true or false
-- if accepted: include drunkardDict 
- Modify frontend: 
-- if accepted, show normal card, if not accepted: show new card with "tap to refresh"
-- refresh: Reload-event (single). 
-- If accepted: 
--- returned event should include drunkard array (full event) & update localStorage and events in Context
--- rebuild single card -> useEffect-Hook with events in dependency array, presumably


New API endpoint: Refresh event and bierstriche:
Get updated data of event
Reset in localStorage
Recompute kings

Check what happens when I do not go via main page. One option: Make check in useEffect hooks: If localStorage empty: navigate("/");

New Header for Events: No Menu button but + button at top right to add new event. Potentially align with Profile Menu (in profile menu also include beer and x)


In "Join event": Update localStorage.
*/

import "./DrunkardMain.css"
import { BSButton } from "../components/Buttons";
import { IoAddOutline } from "react-icons/io5";

export default function Events() {
    const { setIsFullyLoading, setLoadingText} = useGlobalLoaderContext();
    const { profile, events } = useProfileContext();
    
    const navi = useNavigate();


    function sortEvents(input_events) {
        const currentDate = new Date();
        const events = Object.values(input_events);

        const ongoingEvents = events.filter(event => {
          const startDate = new Date(event.startDate);
          const endDate = new Date(event.endDate);
          return startDate <= currentDate && endDate >= currentDate;
        });
        const futureEvents = events.filter(event => {
          const startDate = new Date(event.startDate);
          return startDate > currentDate;
        });
        const pastEvents = events.filter(event => {
          const endDate = new Date(event.endDate);
          return endDate < currentDate;
        });

        // Sort ongoing events by starting date
        ongoingEvents.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
      
        // Sort future events by starting date
        futureEvents.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
      
        // Sort past events by descending end date
        pastEvents.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
      
        // Concatenate the sorted arrays
        const sortedEvents = [...ongoingEvents, ...futureEvents, ...pastEvents];
      
        return sortedEvents;
      }

    useEffect(() => {

        const loadAndAwait = async () => {
            setIsFullyLoading(true);
             setLoadingText("Lade Events");

             /*const storedEvents = await localStorage.getItem("events");
             setEvents(storedEvents);*/

             setIsFullyLoading(false);
             setLoadingText("");
        }
        loadAndAwait();
    }, [setIsFullyLoading, setLoadingText]);


    const shiningEffect = {
        boxShadow: "0 0 5px rgba(0, 0, 255, 0.7)", // Adjust the color and intensity of the glow
    };

    return (
        <div className="Profile">

            <VStack spacing={4} width="100%" alignItems="justify">
                    <Box h={3}/>

                    <Box position="fixed" top="70px" right="10px" zIndex={100}>
                        <BSButton 
                            style={shiningEffect}
                            zIndex={-1}
                            onClick={() => navi("/events/create/new")}
                            width="8"
                            height="10"
                            p={0}
                            borderRadius="full"
                        >
                            <IoAddOutline size="30px"/>
                        </BSButton>
                    </Box>
                {sortEvents(events).map((event, index) => (
                    event.accepted ? 
                    <><LinkedEventCard
                        key={event.eventId}
                        event={event}
                        myuuid={profile.userId}
                    /></>
                    :<>
                    <EventCardApplied
                        key={event.eventId}
                        event={event}
                /></>
                ))
                }
            </VStack>
          
        </div>
    )
}